.error p {
  font-size: 1.3rem;
  font-family: Arial, Helvetica, sans-serif;
  color: #f00;
}

.error button {
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
  background: #eee;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: 0;
}

.products {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.name {
  margin: 0.5rem 0;
}

@media screen and (min-width: 450px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 600px) {
  .products {
    grid-template-columns: repeat(3, 1fr);
  }
}
