body {
  margin: 2rem;
}

img {
  max-width: 100%;
  border-radius: 0.5rem;
}

a {
  text-decoration: none;
  color: #000;
}

h1 {
  font-size: 1.4rem;
}

.animeLeft {
  transform: translateX(-20px);
  opacity: 0;
  animation: animeLeft 0.3s forwards;
}

@keyframes animeLeft {
  to {
    opacity: initial;
    transform: initial;
  }
}

.App {
  display: flex;
  flex-direction: column;
  max-width: 40rem;
  margin: 0 auto;
  min-height: calc(100vh - 4rem);
}

.content {
  flex: 1;
}
