.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  color: #aaa;
  height: 4rem;
  box-sizing: border-box;
}
