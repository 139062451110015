.contact {
  display: grid;
  grid-template-columns: 1fr;
}

.img {
  grid-row: 2;
}

@media screen and (min-width: 580px) {
  .contact {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .img {
    grid-row: 1;
  }
}

.data {
  padding: 0px;
  list-style: none;
}

.data li {
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 0.8rem;
}

.data li::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 2px;
  background: #ddd;
  margin-right: 10px;
}
