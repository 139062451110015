.error p {
  font-size: 1.3rem;
  font-family: Arial, Helvetica, sans-serif;
  color: #f00;
}

.error button {
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
  background: #eee;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: 0;
}

.product {
  display: grid;
  grid-template-columns: 1fr;
}

@media screen and (min-width: 450px) {
  .product {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}

.product img {
  margin-bottom: 2rem;
}

.value {
  padding: 0.5rem;
  background: #91fb5d;
  color: #017e01;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 4px;
}

.description {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  line-height: 1.2rem;
}
